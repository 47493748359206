<template>
  <div class="text-left w-full">
        <div class="item bg-white flex items-center cursor-pointer p-6 mt-6">
          <div
              class="w-1/4 text-c14 font-c4 text-left"
              @click="goToDetail"
          >
            <div class="flex items-center">
              <icon
                  :data="icons.caisse"
                  height="45"
                  width="45"
                  class="mr-4 cursor-pointer"
                  original
              />
              <div class="w-full">
                <div class="text-c20 font-c6">
                  <span> {{ donnes.bankName }} </span>
                </div>
              </div>
            </div>
          </div>

          <div class="w-1/4" @click="goToDetail">
            <div class="text-60 text-c12 font-c3">
              Transactions
            </div>
            <div class="text-black text-c22 font-c5">
              {{ donnes.transactions.length }}
            </div>
          </div>

          <div class="w-1/4" @click="goToDetail">
            <div class="text-60 text-c12 font-c3">
              Solde Total
            </div>
            <div class="text-black text-c22 font-c5">
              {{ refactorAmount(donnes.amount, '.') }}
              <span class="text-90">fcfa</span>
            </div>
          </div>

          <div class="w-1/4 h-45">
            <operationButton class="h-full" :donnes="donnes"/>
          </div>
        </div>
      </div>
</template>

<script>
import caisse from '../../../assets/icons/banque.svg'
import down from '../../../assets/icons/down.svg'
import operationButton from './operationButton'

export default {
  name: "Index",
  components: {
    operationButton
  },
  props: {
    donnes: {
      type: Object,
      default: null
    },
    type: {
      type: String,
      default: 'Caisses par défaut'
    }
  },

  data () {
    return {
      icons: {
        caisse,
        down
      },
      activeOption: false
    }
  },

  methods: {
    goToDetail () {
      // console.log('sjdj')
      // console.log(this.donnes)
      this.activeOption = false
      this.$store.dispatch('saveTemporalItem', this.donnes)
      this.$router.push({path: '/banqueDetail', query: {y: this.donnes.id}})
    },

    gotoControle () {
      this.$router.push('/controleCaisse')
    },

    refactorAmount (a, b) {
      a = '' + a;
      b = b || ' ';
      let c = '',
          d = 0;
      while (a.match(/^0[0-9]/)) {
        a = a.substr(1);
      }
      for (let i = a.length-1; i >= 0; i--) {
        c = (d !== 0 && d % 3 === 0) ? a[i] + b + c : a[i] + c;
        d++;
      }
      return c;
    },
  },
}
</script>

<style lang="scss" scoped>
.svg-icon{
  fill: transparent;
}
.item{
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}
.absolute{
  right: 80px;
  box-shadow: 0px 1px 28px rgba(0, 0, 0, 0.12);
}
</style>
