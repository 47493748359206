<template>
  <popupCharge :activation="charge" v-if="charge"/>
  <div class="p-10 pr-16 all">
    <div class="pt-20">
      <div
        class="flex items-center mt-24"
      >
        <div class="w-full flex text-left text-c36 items-center font-c6">
          <div class="ml-4 w-full">
            Banques
          </div>
        </div>
      </div>

      <div class="mt-6 pl-4" v-if="allData">
        <div class="flex bg-oho-blue-clear w-full p-6 rounded-10 text-white text-c14 text-left items-center">
          <div class="flex items-center w-2/3">
            <icon
                :data="icons.wallet"
                height="45"
                width="45"
                class="mr-4 cursor-pointer"
                original
            />

            <div>
              <div>Total des soldes en banque :</div>
              <div class="font-c6 text-c24">{{ refactorAmount(allData.amount, '.') }} FCFA</div>
            </div>
          </div>

          <div class="w-1/3 flex justify-end pr-6">
            <bouton
                label="Virement entre compte"
                background="white"
                color="black"
                radius="17px"
                height="34px"
                size="10px"
                class="w-2/4"
                @info="retourVirement"
            />
          </div>
        </div>

        <div v-for="(item, index) in allData.banks" :key="index">
          <bloc :donnes="item" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import wallet from '../../assets/icons/walletBank.svg'
import bloc from '../../component/cdevs/banques/liste'
import apiroutes from '../../router/api-routes'
import http from '../../plugins/https'
import popupCharge from '../../component/popup/popupCharge'
import bouton from '../../component/helper/add/button'

export default {
  name: "Index",

  components: {
    bloc,
    popupCharge,
    bouton
  },

  data () {
    return {
      icons: {
        wallet,
      },
      allData: null,
      charge: true
    }
  },

  created () {
    this.allWallet()
    // Close virement type session
    this.$store.dispatch('saveVirement', null)
  },

  methods: {
    allWallet () {
      http.get(apiroutes.baseURL + apiroutes.allBank)
          .then(response => {
            console.log(response)
            this.allData = response
            this.charge = false
          })
          .catch(error => {
            this.charge = false
            console.log(error)
          })
    },

    retourVirement () {
      this.$store.dispatch('saveVirement', 'Interne')
      this.$router.push('/virement')
    },

    refactorAmount (a, b) {
      a = '' + a;
      b = b || ' ';
      let c = '',
          d = 0;
      while (a.match(/^0[0-9]/)) {
        a = a.substr(1);
      }
      for (let i = a.length-1; i >= 0; i--) {
        c = (d !== 0 && d % 3 === 0) ? a[i] + b + c : a[i] + c;
        d++;
      }
      return c;
    },
  }
}
</script>

<style lang="scss" scoped>
.svg-icon {
  fill: transparent;
}
.all{
  min-height: 100vh;
}
</style>
